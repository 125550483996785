import React, { useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { ThemeContext } from 'styled-components';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { useHasMounted, Loader, TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import { Container, Row, Col, SiteContentWrapper } from '@luigiclaudio/ga-baseline-ui/layout';
import { AlertFeedback } from '@luigiclaudio/ga-baseline-ui/alert';
import { useUser } from '@luigiclaudio/ga-auth-theme';
import ConfirmAccountMainContainer from '../components/pageLayout/ConfirmAccountMainContainer';
import SiteFooter from '../components/pageLayout/SiteFooter';

const ConfirmationPage = ({ intl }) => {
    const [user] = useUser();
    const [confirmed, setConfirmed] = useState(false);
    const theme = useContext(ThemeContext);
    const hasMounted = useHasMounted();
    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;

    useEffect(() => {
        if (user) {
            setConfirmed(true);
            setTimeout(() => {
                setConfirmed(false);
            }, 1200);
            setTimeout(() => {
                navigate('/');
            }, 1500);
        }
    }, [user]);

    return (
        <DefaultTemplate
            bgStart={renderBgColor}
            bgEnd={renderBgColor}
            content={
                <SiteContentWrapper>
                    <Container isFullHeight>
                        <Row isFullHeight justifyContent="center">
                            <Col xxs={30}>
                                {hasMounted && (
                                    <ConfirmAccountMainContainer>
                                        <TransitionItem inProp={!user}>
                                            <Loader
                                                label={intl.formatMessage({
                                                    id: 'confirmAccount.confirming',
                                                })}
                                                isFullScreen
                                                fullScreenBackgroundColor={renderBgColor}
                                            />
                                        </TransitionItem>
                                        <AlertFeedback
                                            isFullScreen
                                            alertType="success"
                                            alertMessage={intl.formatMessage({
                                                id: 'confirmAccount.confirmed',
                                            })}
                                            inProp={confirmed}
                                        />
                                    </ConfirmAccountMainContainer>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </SiteContentWrapper>
            }
            footer={<SiteFooter />}
        />
    );
};

ConfirmationPage.propTypes = {
    intl: PropTypes.shape().isRequired,
};

export default injectIntl(ConfirmationPage);
